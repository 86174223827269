"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StickyDirective = void 0;
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
function StickyDirective($window) {
    return {
        restrict: 'A',
        scope: {
            'sticky': '=',
            'stickyCallback': '&',
        },
        //@ts-ignore
        link: ($scope, $element, attributes, $vm) => {
            const allEventDestroy = new rxjs_1.Subject();
            const top = $window.scrollY + $element[0].getBoundingClientRect().top;
            (0, rxjs_1.fromEvent)($window, 'scroll').pipe((0, operators_1.throttleTime)(200 /* ms */), (0, operators_1.startWith)(null), (0, operators_1.map)(() => {
                console.log($window.scrollY);
                return $window.scrollY > (top - ($scope.sticky.topOffset || 0));
            }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.tap)((value) => {
                console.log(value);
                $scope.$applyAsync(() => {
                    $scope.stickyCallback({ value: value });
                });
            })).subscribe();
            // (new Subject()).pipe(
            //     startWith($element[0].getBoundingClientRect().top),
            //     // switchMap(() => {
            //     //     return fromEvent($window, 'scroll').pipe(
            //     //         mapTo(
            //     //             $element[0].getBoundingClientRect().top
            //     //         )
            //     //     )
            //     // }),
            //     tap((top: any) => {
            //         console.log(top)
            //     })
            // ).subscribe()
            // console.log(
            //     $element,
            //     $element[0].getBoundingClientRect().top
            // )
            $scope.$on("$destroy", () => {
                allEventDestroy.next(null);
                allEventDestroy.complete();
            });
        }
    };
}
exports.StickyDirective = StickyDirective;
StickyDirective.$inject = ['$window'];
exports.default = StickyDirective;
